<template>
  <div :class="['pre-register-form', { 'is-not-fixed': isModal }]">
    <div
      v-if="!isModal"
      @click.stop="onClickShow()"
      :class="btnRegisClass"
    >
      <Button
        v-if="!show"
        @click="onClickShow()"
        block
        depressed
        color="color-bnn"
        height="4px"
        title="ลงทะเบียน"
      />
    </div>

    <div v-if="show && !isModal" class="close">
      <v-icon @click.stop="show = false">mdi-close</v-icon>
    </div>
    <div :class="[formClass, { 'is-not-fixed': isModal }]">
      <form :class="['form-customer-layout', { 'mt-0': isModal }]">
        <v-row>
          <v-col cols="12" sm="6">
            <v-autocomplete
              :color="colorBnn['color-bnn']"
              v-model="form.model"
              outlined
              hide-details
              background-color="white"
              class="text-sm"
              :class="{
                'has-error': $v.form.model.$error,
              }"
              :error="$v.form.model.$error"
              :items="modelItems"
              data-pptr="model"
              item-text="model"
              item-value="model"
              @change="persist('model', $event)"
              label="รุ่น / สี (ตัวอย่าง. Pro/Deep Purple)"
              ref="model"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              :color="colorBnn['color-bnn']"
              v-model="form.storage"
              outlined
              hide-details
              background-color="white"
              class="text-sm"
              :class="{
                'has-error': $v.form.storage.$error,
              }"
              :error="$v.form.storage.$error"
              :items="storageItems"
              data-pptr="storage"
              @change="persist('storage', $event)"
              label="ความจุ (1TB/512GB/256GB/128GB)"
              ref="storage"
            ></v-autocomplete>
          </v-col>
          <v-col v-if="promotionItems.length > 0" cols="12" sm="6">
            <v-autocomplete
              :color="colorBnn['color-bnn']"
              v-model="form.promotion"
              outlined
              hide-details
              background-color="white"
              class="text-sm"
              :class="{
                'has-error': $v.form.promotion.$error,
              }"
              :error="$v.form.promotion.$error"
              :items="promotionItems"
              @change="persist('promotion', $event)"
              label="โปรโมชันที่สนใจ"
              ref="promotion"
            ></v-autocomplete>
          </v-col>
          <div class="w-full"></div>
          <v-col cols="12" sm="6">
            <v-text-field
              :color="colorBnn['color-bnn']"
              v-model="form.email"
              outlined
              hide-details
              background-color="white"
              data-pptr="email"
              class="input-customer text-sm mt-2"
              :error="$v.form.email.$error"
              type="email"
              ref="email"
              @change="persist('email', $event)"
              label="อีเมล"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              :color="colorBnn['color-bnn']"
              @keypress="isNumber($event)"
              v-model="form.phone"
              outlined
              hide-details
              background-color="white"
              data-pptr="phone"
              class="input-customer text-sm mt-2"
              :error="$v.form.phone.$error"
              maxlength="10"
              type="tel"
              ref="phone"
              @change="persist('phoneNumber', $event)"
              label="เบอร์โทรศัพท์"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              :color="colorBnn['color-bnn']"
              v-model="form.first_name"
              outlined
              hide-details
              background-color="white"
              data-pptr="first_name"
              class="input-customer text-sm mt-2"
              :error="$v.form.first_name.$error"
              type="text"
              ref="first_name"
              @change="persist('firstName', $event)"
              label="ชื่อ"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              :color="colorBnn['color-bnn']"
              v-model="form.last_name"
              outlined
              hide-details
              background-color="white"
              data-pptr="last_name"
              class="input-customer text-sm mt-2"
              :error="$v.form.last_name.$error"
              type="text"
              ref="last_name"
              @change="persist('lastName', $event)"
              label="นามสกุล"
            />
          </v-col>
          <v-col cols="12" md="12">
            <v-checkbox
              class="mt-0"
              :input-value="form.pdpaMkt"
              :true-value="true"
              :color="colorBnn['color-bnn']"
              @click="onCheckPdpaMkt"
              data-pptr="pdpaMkt"
              ref="pdpaMkt"
            >
              <template v-slot:label>
                <div
                  class="policy-condition-layout"
                  :class="{ '--error': $v.form.pdpaMkt.$error }"
                >
                  <div class="font-semibold">
                    ยินยอมข้อมูลส่วนบุคคลของคุณ
                  </div>
                  <div>
                    <span>
                      บริษัทคอมเซเว่น จำกัด (มหาชน) และบริษัทในเครือ
                      หรือบริษัทในกลุ่ม
                      ใช้คุกกี้เพื่อมอบประสบการณ์การใช้งานเว็บไซต์ที่ดีที่สุดให้คุณ
                      <span
                        @click.stop="openLink"
                        class="text-label-term"
                      >
                        ดูรายละเอียดนโยบายความเป็นส่วนตัว</span
                      >
                    </span>
                  </div>
                </div>
              </template>
            </v-checkbox>
          </v-col>
          <!-- <v-col cols="12" md="12">
                <v-checkbox
                  class="mt-0"
                  :input-value="form.pdpaPromotion"
                  :true-value="true"
                  :color="colorBnn['color-bnn']"
                  @click="form.pdpaPromotion = !form.pdpaPromotion"
                  data-pptr="pdpaPromotion"
                  ref="pdpaPromotion"
                >
                  <template v-slot:label>
                    <span class="text-lg">
                      รับการแจ้งเตือนสิทธิพิเศษผ่าน SMS
                    </span>
                  </template>
                </v-checkbox>
              </v-col> -->
          <div class="my-4"></div>
        </v-row>
      </form>
      <div
        :class="[
          'form-customer-actions form-customer-layout',
          { 'mt-0': isModal },
        ]"
      >
        <div>
          <add-to-calendar-button
            v-if="isModal"
            id="add-to-calendar-button"
            hideCheckmark
            trigger="click"
            listStyle="dropup-static"
            :name="calendarData.name"
            :label="calendarData.label"
            :options="calendarData.options"
            :startDate="calendarData.startDate"
            :endDate="calendarData.endDate"
            :description="calendarData.description"
            :startTime="calendarData.startTime"
            :endTime="calendarData.endTime"
            :timeZone="calendarData.timeZone"
          ></add-to-calendar-button>
        </div>
        <div v-if="isModal" class="btn-text" @click="submit(true)">
          ยืนยันและกลับสู่หน้าหลัก
        </div>
        <div>
          <div v-if="show" class="action-submit">
            <Button
              @clickStop="submit(false)"
              block
              depressed
              color="color-bnn"
              height="4px"
              title="ยืนยันและกรอกที่อยู่จัดส่งล่วงหน้า"
            />
          </div>
          <template v-else>
            <Button
              @clickStop="submit(false)"
              block
              depressed
              color="color-bnn"
              title="ยืนยันและกรอกที่อยู่จัดส่งล่วงหน้า"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Button from '@/components/bnn/Button'
import colorBnn from '@/config/color'
import isBnn from '@/config/isBnn'
import { validationMixin } from 'vuelidate'
import {
  required,
  email,
  maxLength,
  numeric,
} from 'vuelidate/lib/validators'
import moment from 'moment'

export default {
  mixins: [validationMixin],

  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState({
      campaign: state => state.campaign.current,
      startedAt: state => state?.campaign?.current?.data?.started_at,
    }),

    slug() {
      return this.campaign?.data?.slug ?? ''
    },

    campaignId() {
      return this.campaign?.data?.id ?? ''
    },

    formClass() {
      return {
        'form-layout--show': this.show,
        'form-layout': !this.show,
      }
    },

    btnRegisClass() {
      return {
        'btn-register--show': this.show,
        'btn-register': !this.show,
      }
    },

    linkTerm() {
      if (isBnn) {
        return 'https://www.bnn.in.th/th/pages/terms-of-use'
      }
      return 'https://www.studio7thailand.com/th/pages/terms-of-use'
    },

    modelItems() {
      return (
        this.campaign.data?.custom_fields?.models ??
        this.campaign.data?.custom_fields ??
        []
      )
    },

    storageItems() {
      return (
        this.modelItems?.find(i => i.model === this.form.model)
          ?.storage ?? []
      )
    },

    promotionItems() {
      return this.campaign.data?.custom_fields?.promotions ?? []
    },

    calendarData() {
      let link = `${window.location.origin}/${this.slug}/countdown`

      return {
        name: `[${isBnn ? 'BaNANA' : 'Studio7'}] PRE-ORDER ${
          this.campaign?.data?.name
        } `,
        options: "'iCal','Apple','Google','Outlook.com'",
        startDate: moment
          .utc(this.startedAt)
          .local()
          .add(-5, 'minutes')
          .format('YYYY-MM-DD'),
        endDate: moment
          .utc(this.startedAt)
          .local()
          .add(1, 'hour')
          .format('YYYY-MM-DD'),
        startTime: moment
          .utc(this.startedAt)
          .local()
          .add(-5, 'minutes')
          .format('HH:mm'),
        endTime: moment
          .utc(this.startedAt)
          .local()
          .add(1, 'hour')
          .format('HH:mm'),
        label: 'เพิ่มไปปฏิทินของฉัน',
        timeZone: 'Asia/Bangkok',
        description: `[p][strong]${
          isBnn ? 'BaNANA' : 'Studio7'
        } | PRE-ORDER ${
          this.campaign?.data?.name
        }[/strong][/p][hr][p]เริ่ม ${moment
          .utc(this.startedAt)
          .local()
          .format(
            'DD MMMM YYYY เวลา HH:mm น.',
          )}[p][url]${link}|${link}[/url][/p][hr]`,
      }
    },
  },

  data() {
    return {
      colorBnn,
      show: false,
      form: {
        model: localStorage.getItem('model') || '',
        storage: localStorage.getItem('storage') || '',
        first_name: localStorage.getItem('firstName') || '',
        last_name: localStorage.getItem('lastName') || '',
        email: localStorage.getItem('email') || '',
        phone: localStorage.getItem('phoneNumber') || '',
        pdpaMkt: null,
        pdpaPromotion: null,
        promotion: localStorage.getItem('promotion') || '',
      },
    }
  },

  validations: {
    form: {
      model: { required, maxLength: maxLength(40) },
      storage: { required, maxLength: maxLength(40) },
      email: { required, email, maxLength: maxLength(40) },
      phone: { required, numeric, maxLength: maxLength(20) },
      last_name: { required, maxLength: maxLength(40) },
      first_name: { required, maxLength: maxLength(40) },
      pdpaMkt: { required },
      promotion: { required },
    },
  },

  components: {
    Button,
  },

  mounted() {
    const addToCalendarButton = document.querySelector(
      '#add-to-calendar-button',
    )

    // Ensure the element exists and has a shadow root
    if (addToCalendarButton && addToCalendarButton.shadowRoot) {
      // Select an element inside the shadow root
      const shadowElement = addToCalendarButton.shadowRoot.querySelector(
        '.atcb-initialized',
      )

      // Modify the content of the selected element
      if (shadowElement) {
        shadowElement.style = `width: 100% !important;`
      }
    }
  },

  methods: {
    openLink() {
      window.open(this.linkTerm, '_blank')
    },

    onClickShow() {
      this.show = true
    },

    validate() {
      this.$v.$touch()

      if (this.$v.$invalid) {
        for (let key in Object.keys(this.$v.form)) {
          const input = Object.keys(this.$v.form)[key]
          if (input.includes('$')) return false
          if (this.$v.form[input].$error) {
            var elmnt = document.querySelector(
              `[data-pptr='${input}']`,
            )

            elmnt.scrollIntoView({
              // behavior: 'smooth',
              block: 'center',
            })
            if (this?.$refs[input]?.focus) {
              setTimeout(() => this?.$refs[input]?.focus(), 300)
            }
            break
          }
        }
      }
    },

    async submit(isSkip) {
      this.validate()

      if (this.$v.$error) {
        //  console.log('error')
        return
      }

      const payload = {
        first_name: this.form.first_name,
        last_name: this.form.last_name,
        phone: this.form.phone,
        email: this.form.email,
        campaign_id: this.campaignId,
        meta: {
          model: this.form.model,
          storage: this.form.storage,
          pdpa_mkt:
            this.form.pdpaMkt === null ? false : this.form.pdpaMkt,
          promotion: this.form.promotion,
          // pdpa_promotion:
          //   this.form.pdpaPromotion === null
          //     ? false
          //     : this.form.pdpaPromotion,
        },
        type: 'pre_regis',
      }

      await this.$store.dispatch('preRegister/submit', payload)

      this.$emit('submit')

      setTimeout(() => {
        if (isSkip) {
          this.$router.push({
            name: 'CountDown',
            params: { slug: this.slug },
          })
        } else {
          this.$router.push({
            name: 'CustomerInformation',
            params: { slug: this.slug },
          })
        }
      }, 1500)
    },

    onCheckPdpaMkt() {
      if (this.form.pdpaMkt === null) {
        this.form.pdpaMkt = true
      } else {
        this.form.pdpaMkt = null
      }
    },

    persist(key, value) {
      localStorage.setItem(key, value)
    },

    resetValidation() {
      this.$v.$reset()
    },
  },
}
</script>

<style lang="stylus">

.close
  display none
  @media medium
    display: flex
    justify-content: flex-end
  @media small
    display: flex
    justify-content: flex-end

.customer-form
    background $color-bnn-gray
    padding-top 1rem
    padding-bottom 3rem
    margin-top 4rem
    width 100%
.form-customer-layout
    width 100%
    transition: height 0.25s ease;
    margin-top 1rem
    @media medium
      margin-top 1rem
      width 100%
      padding 0 1rem
    @media small
      margin-top 1rem
      width 100%
      padding 0 1rem

.v-text-field.v-text-field--enclosed .v-text-field__details
  margin-bottom 0 !important
.v-messages.theme--light
  min-height 0px !important
.v-input--selection-controls
  margin-top 0px !important
.input-transparent
  background: transparent !important;
  border-color: transparent !important;
.pre-register-form
  width: 100%
  margin-top 0
  background: white
  padding: 0
  &:not(.is-not-fixed)
    @media small
        width: 100%
        background: white
        position fixed
        bottom: 0
        left 0
        z-index: 100
        padding: 2rem 1rem
        box-shadow: 1px 1px 6px #00000029;
        border-radius: 4px
    @media medium
        width: 100%
        background: white
        position fixed
        bottom: 0
        left 0
        z-index: 100
        padding: 2rem 1rem
        box-shadow: 1px 1px 6px #00000029;
        border-radius: 4px

.action-submit
  position relative
  @media small
      position fixed
      bottom 20px
      width 100%
      margin-top -32px
      margin-left: -32px
      padding: 0 2rem
  @media medium
      position fixed
      bottom 20px
      width 100%
      margin-top -32px
      margin-left: -32px
      padding: 0 2rem

.btn-register
  width 100%
  padding 0 1rem
  display: none
  @media medium
    display: block
  @media small
    display: block

.form-layout:not(.is-not-fixed)
  height auto
  width 100%
  transition: max-height 0.15s ease;
  padding-top: 4px
  @media medium
    padding-top 0
    height 0px
    &--show
      height 520px
      transition: height 0.25s ease;
      overflow-y: auto
      overflow-x: hidden
  @media small
    height 0px
    padding-top 0
    &--show
      height 520px
      transition: height 0.25s ease;
      overflow-y: auto
      overflow-x: hidden

.policy-condition-layout
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: flex-start

.text-label-term
  color: $color-bnn
  cursor: pointer

.--error
  color: $color-bnn-red

add-to-calendar-button#add-to-calendar-button::part(atcb-button){
	background-color: #fff
	box-shadow: none
	font-size: 0.875rem
	margin: 0
	border: 2px solid $color-bnn
    color: $color-bnn
    padding: 14px 30px

	// v-btn
    font-family: 'SF Pro Display', 'SF Pro Text', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
	align-items: center;
	border-radius: 32px;
	display: inline-flex;
	flex: 0 0 auto;
	font-weight: 500;
	letter-spacing: 0.0892857143em;
	justify-content: center;
	outline: 0;
	position: relative;
	text-decoration: none;
	text-indent: 0.0892857143em;
	text-transform: uppercase;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	vertical-align: middle;
	white-space: nowrap;
	// height: 50px;
    // width: 48px;

	transition: 0.2s ease-in-out;

	&:hover {
        background-color: white;
		transition: 0.2s ease-in-out;
	}

    @media screen and (max-width: 1023px) {
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
    }

}

.form-customer-actions {
  display: flex
  align-items: center
  gap: 16px
  flex-direction: column
  > div {
    width: 100%
    flex-basis: 100%
  }
  @media screen and (min-width: 1024px) {
    flex-direction: row

    > div {
        width: fit-content
        flex: 0 0 auto
    }
  }
  .btn-text {
    color: $color-bnn
    cursor: pointer
    font-size: 0.875rem
    font-weight: 500
	letter-spacing: 0.0892857143em
    text-transform: uppercase
    text-align: center
    order: 3
    @media screen and (min-width: 1024px) {
      order: unset
      margin-left: auto
    }
  }
}
</style>
